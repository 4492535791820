import React from 'react';
import Maintenance from './pages/Maintenance/Maintenance';

import classes from './pages/Maintenance/Maintenance.module.scss';
import appStyle from './App.module.scss';

const App: React.FC = () => {
  return (
    <div className={appStyle.App}>
      <Maintenance className={classes.Maintenance} />
    </div>
  );
};

export default App;
