import React from 'react';
import PropTypes from 'prop-types';
// import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCogs
} from '@fortawesome/free-solid-svg-icons';

type MaintenanceProps = {
  className?: string
}

const Maintenance: React.FC<MaintenanceProps> = ({ className }) => {
  return (
    <div className={className}>
      <FontAwesomeIcon icon={faCogs} color='#ffffff' size='5x' />
      <div>
        <h2>Sorry, this site is being upgraded!</h2>
        <h3>I&#39;ll be back in no time</h3>
        <h3>You can check my projects on <a href="http://www.github.com/dayoesq">Github</a></h3>
        <h3>Here is the link to <a href="https://www.linkedin.com/in/oladayo-oladapo/" >LinkedIn</a></h3>
      </div>
    </div>
  );
};

Maintenance.propTypes = {
  className: PropTypes.string
};

export default Maintenance;
